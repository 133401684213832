<template>
  <v-container fluid>
    <v-row>
      <v-breadcrumbs :items="breadCrumbsItems" large>
        <template v-slot:divider>
          <v-icon size="11">mdi-greater-than</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>

    <v-row>
      <v-col cols="12" md="3">
        <UserMenu></UserMenu>
      </v-col>

      <v-col cols="12" md="9" class="mt-2">
        <v-row class="rowBg pa-5">
          <v-col
            cols="12"
            style="background-color:#ffffff;border-radius:10px;"
          >

           <v-layout wrap>
               <v-col cols="12">
                    <h2>
                        Get Started With Your Prescription
                    </h2>
                   <v-layout wrap>
                       <v-col cols="12">
                        <v-radio-group
                        column
                        >
                        <v-radio
                            label="Upload new drug prescription"
                            value="radio-1"
                            color="#000000"
                            @click="startPrescription"
                        ></v-radio>
                        <v-radio
                            label="Update Prescription"
                            value="radio-2"
                            color="#000000"
                            @click="startRefillPrescription"
                        ></v-radio>
                        </v-radio-group>
                       </v-col>
                   </v-layout>
               </v-col>
           </v-layout>


            <!-- New Prescription --->
           <v-layout wrap v-if="newPrescription">
               <v-col cols="12">
                <h3>
                    Upload Drug Prescription
                </h3>

                <v-layout wrap>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="Prescriber's Name"
                            v-model="prescriptionData.prescriberName"
                            outlined
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            type="email"
                            v-model="prescriptionData.prescriberEmail"
                            label="Prescriber's Email"
                            outlined
                            dense
                        ></v-text-field>
                    </v-col>
                </v-layout>

                <v-layout wrap>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="Prescriber's Phone"
                            v-model="prescriptionData.prescriberPhoneNumber"
                            outlined
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="Hospital Name"
                            v-model="prescriptionData.hospitalName"
                            outlined
                            dense
                        ></v-text-field>
                    </v-col>
                </v-layout>

                <v-layout wrap>
                    <v-col cols="12">
                        <v-text-field
                            label="Hospital Full Address"
                            v-model="prescriptionData.hospitalAddress"
                            outlined
                            dense
                        ></v-text-field>
                    </v-col>
                </v-layout>


                <v-layout wrap>
                    <v-col cols="12">
                         <v-select  
                          label="Drug Name"
                          :items="prescriptionProducts"
                          v-model="prescriptionData.drugName"
                          outlined
                          item-text="name"
                          item-value="productId"
                          multiple
                          chips
                          persistent-hint
                          hint="Select from our list of Prescription drugs"
                          dense>
                        </v-select>
                    </v-col>
                    <!-- <v-col cols="12" md="6">
                        <v-text-field
                            label="Dosage"
                             v-model="prescriptionData.dosage"
                            outlined
                            dense
                        ></v-text-field>
                    </v-col> -->
                </v-layout>

               
                 <v-layout>
                            <v-col cols="12" md="10">
                              <v-icon size="100" @click="onPickAvatar">
                                mdi-camera
                              </v-icon> Upload Prescription File/ (Only .Pdf,.Png,.Jpg,.Jpeg Files are Allowed)
                              <input
                                type="file"
                                style="display:none;"
                                ref="fileInput"
                                accept="image/*"
                                @change="avatarPicked"
                              />
                            </v-col>

                            <v-col cols="12" md="2" class="mt-3" v-if="uploadDone">
                              <v-icon size="50" color="green">
                                mdi-checkbox-marked-circle-outline
                              </v-icon>
                            </v-col>
                          </v-layout>

                <v-layout wrap>
                    <v-col cols="12">
                        <v-textarea
                            outlined
                            name="input-7-4"
                            v-model="prescriptionData.additionalNote"
                            label="Additional Note"
                            value="Note about your prescription"
                        ></v-textarea>
                    </v-col>
                </v-layout>

                <v-row>
                        <v-progress-linear
                          color="#7CBF46"
                          v-show="loader"
                          :indeterminate="true"
                        ></v-progress-linear>
                      </v-row>

                <v-layout wrap>
                    <v-col cols="12">
                        <v-btn  align="center"
                        class="buttonStyle" @click="postPrescription">
                            Submit Prescription
                        </v-btn>
                    </v-col>
                </v-layout>
               </v-col>
           </v-layout>

            <!-- Refill Prescription --->
           <v-layout wrap v-if="refillPrescription">
               <v-col cols="12">

                <v-layout wrap>
                    <v-col cols="12" md="6">
                        <h4>
                            Update Prescription
                        </h4>
                    </v-col>
                </v-layout>

                <v-layout>
                  <v-progress-linear
                          color="#7CBF46"
                          v-show="loader"
                          :indeterminate="true"
                  ></v-progress-linear>
                </v-layout>

                <v-layout wrap>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="Prescription Id"
                            v-model="prescriptionId"
                            outlined
                            dense
                        ></v-text-field>
                    </v-col>
                </v-layout>

                <v-layout wrap>
                    <v-col cols="12">
                        <v-btn  align="center"
                        class="buttonStyle" @click="searchPrescription">
                            Search Prescription
                        </v-btn>
                    </v-col>
                </v-layout>

                <v-layout wrap v-if="updatePrescriptionToggle">
                  <v-col cols="12">
                    <h3>
                        Update Drug Prescription
                    </h3>

                    <v-layout wrap>
                        <v-col cols="12" md="6">
                            <v-text-field
                                label="Prescriber's Name"
                                v-model="prescriptionSingleData.prescriberName"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                type="email"
                                v-model="prescriptionSingleData.prescriberEmail"
                                label="Prescriber's Email"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-layout>

                    <v-layout wrap>
                        <v-col cols="12" md="6">
                            <v-text-field
                                label="Prescriber's Phone"
                                v-model="prescriptionSingleData.prescriberPhoneNumber"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                label="Hospital Name"
                                v-model="prescriptionSingleData.hospitalName"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-layout>

                    <v-layout wrap>
                        <v-col cols="12">
                            <v-text-field
                                label="Hospital Full Address"
                                v-model="prescriptionSingleData.hospitalAddress"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-layout>

                    <v-layout wrap>
                        <v-col cols="12">
                            <v-text-field
                                label="Prescription Drugs"
                                v-model="prescriptionSingleData.drugName"
                                readonly
                            ></v-text-field>
                        </v-col>
                    </v-layout>

                    <v-layout wrap>
                        <v-col cols="12">
                            <v-textarea
                                outlined
                                name="input-7-4"
                                v-model="prescriptionSingleData.additionalNote"
                                label="Additional Note"
                                value="Note about your prescription"
                            ></v-textarea>
                        </v-col>
                    </v-layout>

                    <v-row>
                            <v-progress-linear
                              color="#7CBF46"
                              v-show="loader2"
                              :indeterminate="true"
                            ></v-progress-linear>
                    </v-row>

                    <v-layout wrap>
                        <v-col cols="12">
                            <v-btn  align="center"
                            class="buttonStyle" @click="updatePrescription">
                                Update Prescription
                            </v-btn>
                        </v-col>
                    </v-layout>
                  </v-col>
              </v-layout>
               </v-col>
           </v-layout>
          </v-col>

        </v-row>

        <!--- Success Modal ---->
        <v-dialog v-model="modalSuccess" persistent max-width="350">
                            <v-card
                              style="border-radius:15px;height:auto; padding:10px;"
                            >
                              <v-card-text>
                                <v-row wrap>
                                  <v-col cols="12" align="center" justify="center">
                                    <v-icon style="color:#456928; font-size:42px;"
                                      >mdi-checkbox-marked-circle-outline</v-icon
                                    >
                                  </v-col>
                                </v-row>
                                <v-row wrap>
                                  <v-col cols="12" align="center" justify="center">
                                    <h4>Prescription uploaded successfully</h4>
                                  </v-col>
                                </v-row>

                                <v-row wrap>
                                  <v-col cols="12" align="center" justify="center">
                                    <v-btn
                                      block
                                      dark
                                      color="#456928"
                                      @click="redirectLogin"
                                    >
                                      OK
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
        </v-dialog>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserMenu from "../others/UserMenu.vue";
import { Country } from "country-state-city";

export default {
  name: "uploadPrescription",
  data: () => ({
    modalSuccess:false,
    updatePrescriptionToggle:false,
    loader: false,
    loader2: false,
    newPrescription: false,
    refillPrescription: false,
    prescriptionId:"",
    breadCrumbsItems: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Upload Prescription",
        disabled: true,
        href: "/#/upload/user-prescription",
      },
    ],
    prescriptionProducts:[],
    country: [],
    systemSetting: {
      systemCurrency: "₦",
    },
    user_id: "",
    uploadDone:false,
    prescriptionData: {
      prescriberName:"",
      prescriberEmail:"",
      prescriberPhoneNumber:"",
      hospitalName:"",
      hospitalAddress:"",
      drugName:"",
      dosage:"",
      additionalNote:"",
      file: null
    },
    prescriptionSingleData:{
      prescriberName:"",
      prescriberEmail:"",
      prescriberPhoneNumber:"",
      hospitalName:"",
      hospitalAddress:"",
      drugName:"",
      dosage:"",
      additionalNote:"",
    },
    image: "",
    userDetail:{}
  }),
  components: {
    UserMenu,
  },
  created() {
    this.setAuthenticatedUser();
    this.user_id = localStorage.getItem("userId");
    this.getCountry();
    this.fetchPrespProducts();
  },
  methods: {
    setAuthenticatedUser() {
      this.$http
        .get(`${process.env.VUE_APP_URL}user/` + localStorage.getItem("userId"))
        .then((response) => {
          this.userDetail = response.data.data;
        })
    },

    fetchPrespProducts() {
       this.$http
        .get(`${process.env.VUE_APP_URL}products`)
        .then((response) => {
          response.data.data.forEach(element => {
            if(element.needsPrescription == true && element.User.Role.name == "Retailer"){
              this.prescriptionProducts.push(element);
            }
          });
        });
    },
    getCountry() {
      this.country = Country.getAllCountries();
    },

    onPickAvatar() {
      this.$refs.fileInput.click();
    },

    avatarPicked(e) {
      const selectedFiles = e.target.files[0];
      this.uploadDone = true;
      this.image = selectedFiles;

      let reader = new FileReader();
      reader.onload = () => {
        this.$refs.image.src = reader.result;
      };

      reader.readAsDataURL(this.image);
    },


    postPrescription() {
          this.loader = true;
          const formData = new FormData();

          formData.append('file', this.image);
          formData.append("userId", this.user_id);
          formData.append("prescriberName", this.prescriptionData.prescriberName);
          formData.append("prescriberEmail", this.prescriptionData.prescriberEmail);
          formData.append("prescriberPhoneNumber", this.prescriptionData.prescriberPhoneNumber);
          formData.append("hospitalName", this.prescriptionData.hospitalName);
          formData.append("hospitalAddress", this.prescriptionData.hospitalAddress);
          formData.append("drugName", this.prescriptionData.drugName);
           formData.append("dosage", 1);
          formData.append("additionalNote", this.prescriptionData.additionalNote);

          this.$http
            .post(`${process.env.VUE_APP_URL}prescription`,
            formData,
            )
            .then(() => {
                this.loader = false;
                this.modalSuccess = true;
            })
            .catch((err) => {
              console.log(err.response)
            });
    },

    updatePrescription() {
          this.loader2 = true;
          this.modalSuccess = false;
          const formData = new FormData();

          formData.append("prescriberName", this.prescriptionSingleData.prescriberName);
          formData.append("prescriberEmail", this.prescriptionSingleData.prescriberEmail);
          formData.append("prescriberPhoneNumber", this.prescriptionSingleData.prescriberPhoneNumber);
          formData.append("hospitalName", this.prescriptionSingleData.hospitalName);
          formData.append("hospitalAddress", this.prescriptionSingleData.hospitalAddress);
          formData.append("drugName", this.prescriptionSingleData.drugName);
           formData.append("dosage", this.prescriptionSingleData.dosage);
          formData.append("additionalNote", this.prescriptionSingleData.additionalNote);

          this.$http
            .post(`${process.env.VUE_APP_URL}prescription/` + this.prescriptionId,
            formData,
            )
            .then((response) => {
                console.log(response);
                this.loader2 = false;
                this.modalSuccess = true;
            })
            .catch((err) => {
              this.loader2 = false;
              console.log(err.response)
            });
    },

    startPrescription() {
        this.refillPrescription = false;
        this.newPrescription = true;
    },

    startRefillPrescription() {
        this.newPrescription = false;
        this.refillPrescription = true;
    },

    searchPrescription() {
      this.loader = true;
      this.updatePrescriptionToggle = false;
       this.$http
        .get(`${process.env.VUE_APP_URL}prescription/` + this.prescriptionId)
        .then((response) => {
          this.loader = false;
          this.prescriptionSingleData = response.data.data;
          this.updatePrescriptionToggle = true;
          console.log(response);
        })
        .catch((err) => {
          this.loader = false;
          console.log(err.response);
        })
    },
    
    redirectLogin() {
      this.$router.push("/prescription/dashboard/en");
    },
  },
};
</script>

<style scoped>
.priceStyle2 {
  width: 80px;
  height: auto;
  background-color: #bd2121;
  color: #ffffff;
  padding: 5px;
  border-bottom-right-radius: 5px;
}


.buttonStyle {
  margin-top: 10px;
  width: auto;
  height: auto;
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 12px;
}

.rowBg {
  background-color: #f2f2f2;
  border-radius: 10px;
}

.iconPadding {
  padding: 50px !important;
}

.iconStyle {
  color: yellowgreen;
  font-size: 45px;
}
</style>
